.test-content {
  height: fit-content;
  width: 100%;
  border-radius: 30px;
  background-color: white;
}
.test-content .question {
  padding: 30px;
  border-bottom: 2px solid rgba(81, 127, 223, 0.2);
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
}
.test-content .question p {
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
}
.test-content .answers-wrap {
  padding: 30px;
  padding-top: 20px;
}
.test-content .answers-wrap h6 {
  font-weight: 400;
  opacity: 0.8;
  margin-bottom: 20px;
}
.test-content .answers-wrap .answers-item {
  display: flex;
  align-items: center;
}
.test-content .answers-wrap .answers-item label {
  font-size: 20px;
  line-height: 25px;
}
.test-content .answers-wrap .answers-item + .answers-item {
  margin-top: 20px;
}
.test-content .answers-wrap .answers-item--correct label {
  color: #6fcf97;
}
.test-content .answers-wrap .answers-item--correct input[type='checkbox'] {
  background: #6fcf97;
}
.test-content .answers-wrap .answers-item--correct input[type='checkbox']::after {
  box-shadow: inset 0 0 0 1px #6fcf97;
}
.test-content .answers-wrap .answers-item--incorrect label {
  color: #f56b76;
}
.test-content .answers-wrap .answers-item--incorrect input[type='checkbox'] {
  background: #f56b76;
}
.test-content .answers-wrap .answers-item--incorrect input[type='checkbox']::after {
  box-shadow: inset 0 0 0 1px #f56b76;
}
.test-content .solution {
  padding: 30px;
  border-top: 2px solid rgba(81, 127, 223, 0.2);
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
}
.test-content .solution h4 {
  color: #517fdf;
  margin-bottom: 20px;
}
.test-content .solution a {
  display: block;
  margin-top: 15px;
  color: #fdc651;
  text-decoration: none;
}
.ui-checkbox {
  display: flex;
  align-items: center;
}
.ui-checkbox label {
  padding-left: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #517fdf;
  user-select: none;
  cursor: pointer;
}
.ui-checkbox input[type='checkbox'] {
  width: 20px;
  height: 20px;
  position: relative;
  background: #517fdf;
  border: none;
  border-radius: 4px;
  transition: 0.3s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  cursor: pointer;
}
.ui-checkbox input[type='checkbox']::before {
  position: absolute;
  top: 1px;
  left: 1px;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18px' height='18px' fill='none' stroke-width='2px' stroke='white' stroke-linecap='round' viewBox='0 0 21 21'%3E%3Cpolyline points='5 10.75 8.5 14.25 16 6'%3E%3C/polyline%3E%3C/svg%3E");
  transform: scale(0);
}
.ui-checkbox input[type='checkbox']:checked::before {
  -webkit-animation: scale-in 0.1s 0.2s linear forwards;
  animation: scale-in 0.1s 0.2s linear forwards;
}
.ui-checkbox input[type='checkbox']::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background: white;
  border-radius: inherit;
  box-shadow: inset 0 0 0 1px #517fdf;
  transition: 0.2s;
}
.ui-checkbox input[type='checkbox']:checked::after {
  transform: scale(0);
}
@keyframes scale-in {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.ui-checkbox--medium input[type='checkbox'] {
  width: 30px;
  height: 30px;
}
.ui-checkbox--medium input[type='checkbox']::before {
  position: absolute;
  top: 3px;
  left: 3px;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25px' height='25px' fill='none' stroke-width='2px' stroke='white' stroke-linecap='round' viewBox='0 0 21 21'%3E%3Cpolyline points='5 10.75 8.5 14.25 16 6'%3E%3C/polyline%3E%3C/svg%3E");
  transform: scale(0);
}

.MathJax {
  display: inline !important;
}

.test-preview::-webkit-scrollbar {
  width: 5px;               /* ширина scrollbar */
}
.test-preview::-webkit-scrollbar-track {
  background: transparent;        /* цвет дорожки */
}
.test-preview::-webkit-scrollbar-thumb {
  background-color: white;    /* цвет плашки */
  border-radius: 20px;       /* закругления плашки */
}