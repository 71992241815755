pre .string {
  color: green;
}
pre .number {
  color: darkorange;
}
pre .boolean {
  color: blue;
}
pre .null {
  color: magenta;
}
pre .key {
  color: red;
}


.json-editor input {
  font-family: monospace !important;
}